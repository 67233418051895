<template>
  <div>
    <div
      class="landing-content"
    >
      <div class="landing-title">
        <span
          class="text-h2 azure-text mb-10 font-weight-regular" style="max-width: 26rem; font-size: 4.5rem !important"
        >
          Develop formu&shy;lations
          <span class="font-weight-black font-italic">
            faster.
          </span>
        </span>
        <span
          class="text-h5 mb-10"
          style="max-width: 34rem"
        >
          {{ $t("AppSubtitle") }}
        </span>
        <div style="display: block">
          <v-row>
            <v-col cols="auto">
              <v-btn
                dark
                large
                style="font-size: 16px;"
                color="var(--eastman-azure)"
                to="/register"
              >
                {{ $t('RegisterButtonText') }}
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn
                color="var(--eastman-azure)"
                @click="onLogInClick"
                outlined
                large
                style="font-size: 16px;"
              >
                {{ $t('AppLoginBtn') }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="landing-image">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  Name: 'Landing',
  data: function() {
    return {
      appName: process.env.VUE_APP_NAME,
    }
  },
  methods: {
    onLogInClick: function () {
      this.$msal.signIn()
    },
  },
  mounted: function () {
    if (this.$msal.isAuthenticated())
    {
      this.$router.push('/coatings/predict')
    }
  },
}
</script>

<style scoped>

.landing-content {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 128px);
  width: 100%;
}

.landing-title {
  display: flex;
  min-width: 50%;
  flex-direction: column;
  justify-content: center;
  padding-left: calc(10vw + 20px);
  padding-right: 1em;
  background-color: #FAFAFA;
}

.landing-image {
  width: 50%;
  height: 100%;
  background-image: url('/FastERLandingSplash.png');
  background-size: cover;
  background-position: center;
  background-color: var(--eastman-blue-transparent);
  opacity: 0.8;
}

</style>